import x_btn from "../image/x_menuicon@3x.png";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import nametecLogo from "../image/nametecLogo.png";
import backarrow from "../data/backarrow@3x.png";

const Modal2 = function ({ display, modalHandler, text2 }) {
  const [login, setLogin] = useState(false);

  const loginCheck = () => {
    if (localStorage.getItem("customeremail") == null) {
      setLogin(false);
    } else {
      setLogin(true);
    }
  };
  useEffect(() => {
    loginCheck();
  }, []);
  const loginHandler = () => {};
  const logoutPage = () => {
    localStorage.clear();
    loginCheck();
  };
  return (
    <div
      id="menuContainer"
      style={{
        display: display,
        position: "fixed",
        top: "0px",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        fontFamily: "Bold",
        fontSize: "24px",
        maxWidth: "520px",
        margin: "auto",
        zIndex: "20000",
      }}
    >
      <div style={{ backgroundColor: "#ffffff", width: "100%" }}>
        <div
          className="menuicon1"
          style={{
            position: "relative",
            backgroundColor: "#FFFFFF",
            zIndex: 10010,
            maxWidth: "520px",
            margin: "auto",
            borderBottom: "1px solid #e0e0e0",
            height: "55px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0 16px",
          }}
        >
          {/* 왼쪽: 뒤로가기 아이콘 */}
          <div>
            <Link to="/">
              <img
                className="topbar-backbutton"
                src={backarrow}
                style={{
                  objectFit: "contain",
                  width: "14px",
                  height: "23px",
                }}
                alt="<"
              />
            </Link>
          </div>

          {/* 가운데: 제목 */}
          <div
            style={{
              flexGrow: 1,
              textAlign: "center",
              position: "relative",
              top: "-3.5px",
              left: "-3px",
            }}
          >
            <span
              className="Bold"
              style={{
                fontSize: "20px",
                color: "black",
              }}
            >
              {text2}
            </span>
          </div>

          {/* 오른쪽: x 버튼 */}
          <div>
            <img
              src={x_btn}
              id="xbtn"
              onClick={modalHandler}
              style={{
                cursor: "pointer",
                width: "18px",
              }}
              alt="닫기"
            />
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#4a64f5",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            gap: "20px",
            width: "60%",
            height: "4860.31px",
            fontSize: "20px",
            paddingRight: "20px",
            float: "right",
            paddingTop: "40px",
          }}
        >
          {/* 메인화면: 위쪽에 margin-top 40px 적용 */}
          <Link to="/" style={{ textDecoration: "none" }}>
            <span
              style={{
                cursor: "pointer",
                color: "#fff",
                marginTop: "40px",
              }}
            >
              메인화면
            </span>
          </Link>

          {text2 !== "마이페이지" &&
            (login ? (
              <Link to="/mypage" style={{ textDecoration: "none" }}>
                <span
                  style={{
                    cursor: "pointer",
                    color: "#fff",
                  }}
                  onClick={modalHandler}
                >
                  마이페이지
                </span>
              </Link>
            ) : (
              <Link to="/login" style={{ textDecoration: "none" }}>
                <span
                  style={{
                    cursor: "pointer",
                    color: "#fff",
                  }}
                  onClick={loginHandler}
                >
                  로그인
                </span>
              </Link>
            ))}

          {text2 !== "비용 및 절차 안내" && (
            <Link to="/costprocess" style={{ textDecoration: "none" }}>
              <span style={{ cursor: "pointer", color: "#fff" }}>
                비용 및 절차 안내
              </span>
            </Link>
          )}

          <a
            href="https://blog.naver.com/name-tec"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            <span style={{ cursor: "pointer", color: "#fff" }}>칼럼</span>
          </a>
          {text2 !== "고객센터" && (
            <Link to="/center" style={{ textDecoration: "none" }}>
              <span style={{ cursor: "pointer", color: "#fff" }}>고객센터</span>
            </Link>
          )}

          <Link to="/" style={{ textDecoration: "none" }}>
            <span
              style={{
                cursor: "pointer",
                display: login ? "inline-block" : "none",
                color: "#fff",
              }}
              onClick={() => {
                modalHandler();
                logoutPage();
              }}
            >
              로그아웃
            </span>
          </Link>

          {/* 네임텍 특허법률사무소: div로 감싸서 한 줄 전체 흰색 */}
          <div
            style={{
              width: "100%",
              backgroundColor: "#fff",
              padding: "10px",
              position: "relative",
              left: "20px",
            }}
          >
            <a
              href="https://www.nametec.co.kr/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: "none",
                display: "block",
                textAlign: "right",
              }}
            >
              <span style={{ cursor: "pointer", color: "#4a64f5" }}>
                네임텍 특허법률사무소
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal2;
