import nametecLogo from "../image/nametecLogo.png";
import menu from "../image/menu.png";
import React, { Component } from "react";
import { useState } from "react";
import Modal2 from "./Modal2";
import "../text.css";
import { Link } from "react-router-dom";
import backarrow from "../data/backarrow@3x.png";

export default function MainHeader2({ text }) {
  const [display, setDisplay] = useState("none");

  const modalHandler = () => {
    if (display === "none") {
      setDisplay("inline-block");
    } else {
      setDisplay("none");
    }
    return;
  };

  return (
    <>
      <div
        style={{
          position: "relative",
          backgroundColor: "#FFFFFF",
          zIndex: 10010,
          maxWidth: "520px",
          margin: "auto",
          borderBottom: "1px solid #e0e0e0",
          height: "55px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0 16px",
        }}
      >
        <Link to="/">
          <img
            className="topbar-backbutton"
            src={backarrow}
            style={{
              objectFit: "contain",
              width: "14px",
              height: "23px",
            }}
            alt="<"
          />
        </Link>

        <div
          className="Bold"
          style={{
            fontSize: "20px",
            color: "black",
            textAlign: "center",
          }}
        >
          {text}
        </div>

        <img
          src={menu}
          style={{
            cursor: "pointer",
            width: "24px",
            height: "17px",
          }}
          onClick={modalHandler}
          alt="menu"
        />
      </div>
      <Modal2 display={display} modalHandler={modalHandler} text2={text} />
    </>
  );
}
