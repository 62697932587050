import React, { useState, useEffect, memo } from "react";
import "../style.css";
import "../text.css";
import TopbarComponentWithoutPage from "../components/Topbarwithoutpage";
import high from "../image/high.png";
import checkgray from "../image/1.png";
import checkblue from "../image/2.png";
import bluearr from "../image/bluearr.jpg";
import rechoice from "../image/re.png";
import ask5 from "../image/zma.png";
import kakaoaa from "../image/kakaoaa.png";
import loadingdot from "../image/loadingdot.gif";
import asdzx from "../image/asdzx.png";
import load_mo from "../image/load_mo.png";
import Nextbutton from "../components/Nextbutton";
import nextbtn_new from "../image/nextbtn_new.png";
import $ from "jquery";
import new_blue from "../image/new_blue.png";
import af389 from "../image/1f389.png";
import af3892 from "../image/se_mo.png";
import sclock from "../image/time.png";
import {
  BrowserRouter,
  Route,
  Switch,
  Link,
  NavLink,
  useParams,
} from "react-router-dom";
import {
  BrowserView,
  MobileView,
  isBrowser,
  IsMobile,
  TabletView,
} from "react-device-detect";
import BrowserHeader from "../components/BrowserHeader";
var goodsList = [];
function addDay(date) {
  // month달 후의 1일
  let addMonthFirstDate = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() + 1
  );

  var year = addMonthFirstDate.getFullYear();
  var month = new String(addMonthFirstDate.getMonth() + 1);
  var day = new String(addMonthFirstDate.getDate());

  if (month.length == 1) {
    month = "0" + month;
  }
  if (day.length == 1) {
    day = "0" + day;
  }

  return year + "년 " + month + "월 " + day + "일";
}
function addMonth(date, month) {
  // month달 후의 1일
  let addMonthFirstDate = new Date(
    date.getFullYear(),
    date.getMonth() + month,
    date.getDate()
  );

  // month달 후의 말일
  let addMonthLastDate = new Date(
    addMonthFirstDate.getFullYear(),
    addMonthFirstDate.getMonth() + month,
    0
  );

  let result = addMonthFirstDate;
  if (date.getDate() > addMonthLastDate.getDate()) {
    result.setDate(addMonthLastDate.getDate());
  } else {
    result.setDate(date.getDate());
  }

  var year = result.getFullYear();
  var month = new String(result.getMonth() + 1);
  var day = new String(result.getDate());

  if (month.length == 1) {
    month = "0" + month;
  }
  if (day.length == 1) {
    day = "0" + day;
  }

  return year + "년 " + month + "월 " + day + "일";
}
function addMonth4(date, month) {
  // month달 후의 1일
  let addMonthFirstDate = new Date(
    date.getFullYear(),
    date.getMonth() + month,
    date.getDate()
  );

  // month달 후의 말일
  let addMonthLastDate = new Date(
    addMonthFirstDate.getFullYear(),
    addMonthFirstDate.getMonth() + month,
    0
  );

  let result = addMonthFirstDate;
  if (date.getDate() > addMonthLastDate.getDate()) {
    result.setDate(addMonthLastDate.getDate());
  } else {
    result.setDate(date.getDate());
  }

  var year = result.getFullYear();
  var month = new String(result.getMonth() + 1);
  var day = new String(result.getDate());

  if (month.length == 1) {
    month = "0" + month;
  }
  if (day.length == 1) {
    day = "0" + day;
  }

  return year + "년 " + month + "월";
}
function addMonth2(date, month) {
  // month달 후의 1일
  let addMonthFirstDate = new Date(
    date.getFullYear(),
    date.getMonth() + month,
    date.getDate()
  );

  // month달 후의 말일
  let addMonthLastDate = new Date(
    addMonthFirstDate.getFullYear(),
    addMonthFirstDate.getMonth() + month,
    0
  );

  let result = addMonthFirstDate;
  if (date.getDate() > addMonthLastDate.getDate()) {
    result.setDate(addMonthLastDate.getDate());
  } else {
    result.setDate(date.getDate());
  }

  var year = result.getFullYear();
  var month = new String(result.getMonth() + 1);
  var day = new String(result.getDate());

  if (month.length == 1) {
    month = "0" + month;
  }
  if (day.length == 1) {
    day = "0" + day;
  }

  return year + "년 " + month + "월 " + day + "일";
}

let janLastDate = new Date();
var tomorow = addDay(janLastDate);
var month12 = addMonth(janLastDate, 15);
var month5 = addMonth(janLastDate, 3);
var month52 = addMonth2(janLastDate, 3);
function AnimalFriends(props) {
  const animals = props.skarb;
  const handleClick = (event, animal) => {
    event.preventDefault(); // 기본 동작 방지
    props.onClick1(props, animal);
  };

  const animalList = animals.map((animal) => (
    <div key={animal.id} className="Bold" style={{ marginLeft: "20px" }}>
      <div style={{ height: "10px" }}></div>
      <img
        onClick={(e) => handleClick(e, animal)}
        onTouchEnd={(e) => handleClick(e, animal)} // 터치 이벤트 추가
        src={animal.valuelist2}
        style={{
          width: "25px",
          float: "left",
          marginTop: "-3px",
          cursor: "pointer",
        }}
      />
      <a
        id={animal.valuelist[0]}
        onClick={(e) => handleClick(e, animal)}
        onTouchEnd={(e) => handleClick(e, animal)} // 터치 이벤트 추가
        className="Bold"
        style={{
          fontSize: "16px",
          marginLeft: "5px",
          marginTop: "15px",
          color: "#4a64f5",
          cursor: "pointer",
        }}
      >
        {animal.valuelist}
      </a>
    </div>
  ));

  return <div>{animalList}</div>;
}
function AnimalFriends2(props) {
  const animals = props.skarb;
  const animalList = animals.map((animal) => (
    <div
      key={animal.id}
      className="Bold"
      style={{ marginTop: "12.5px", marginLeft: "40px" }}
    >
      <img
        onClick={() => props.onClick1(props, animal)}
        src={animal.valuelist2}
        style={{
          width: "35px",
          float: "left",
          marginTop: "-3px",
          cursor: "pointer",
        }}
      />
      <a
        id={animal.valuelist[0]}
        onClick={() => props.onClick1(props, animal)}
        className="Bold"
        style={{
          fontSize: "22px",
          marginLeft: "5px",
          color: "#4a64f5",
          cursor: "pointer",
        }}
      >
        {animal.valuelist}
      </a>
      <div></div>
    </div>
  ));

  return <div>{animalList}</div>;
}

var valuelist3 = [];
var valuelist4 = [];
function Success() {
  function addMonth(date, month) {
    // month달 후의 1일
    let addMonthFirstDate = new Date(
      date.getFullYear(),
      date.getMonth() + month,
      date.getDate()
    );

    // month달 후의 말일
    let addMonthLastDate = new Date(
      addMonthFirstDate.getFullYear(),
      addMonthFirstDate.getMonth() + month,
      0
    );

    let result = addMonthFirstDate;
    if (date.getDate() > addMonthLastDate.getDate()) {
      result.setDate(addMonthLastDate.getDate());
    } else {
      result.setDate(date.getDate());
    }

    var year = result.getFullYear();
    var month = new String(result.getMonth() + 1);
    var day = new String(result.getDate());

    if (month.length == 1) {
      month = "0" + month;
    }
    if (day.length == 1) {
      day = "0" + day;
    }

    return year + "년 " + month + "월 " + day + "일";
  }
  let janLastDate = new Date();
  var month12 = addMonth(janLastDate, 15);
  var month121 = addMonth4(janLastDate, 15);
  var month1211 = addMonth4(janLastDate, 3);
  const [isLoaded, setIsLoaded] = useState(false);
  const [goodslist8, setGoodslist8] = useState([]);
  const [monthtext, setMonthtext] = useState(month121);
  const [valuelist, setValuelist] = useState([]);
  const [valuelist2, setValuelist2] = useState([]);
  const [brandnamet, setBrandnamet] = useState(2);
  let selectclasses = sessionStorage.getItem("selectclasses");
  let selectclasses2 = selectclasses.split(",");
  const [count, setCount] = useState(selectclasses2.length);
  const [fcount, setFcount] = useState(0);
  const [fprice, setFprice] = useState(0);
  const [price1, setPrice1] = useState(0);
  const [price2, setPrice2] = useState(0);
  const [testCount, setTestCount] = useState(true);

  useEffect(() => {
    let customeremail2 = "test";
    let customername2 = "test";
    let encodeGoods = sessionStorage.getItem("brandname");
    let tokenName = sessionStorage.getItem("token");
    let encodeClass = sessionStorage.getItem("selectclasses");

    $.ajax({
      url: "https://nametecadmin.kr/nokiprisapi3",

      type: "get",

      accept: "application/json",

      data: {
        상표명: encodeGoods,
        류: encodeClass,
        customername: customername2,
        customeremail: customeremail2,
        token: tokenName,
      },

      dataType: "JSON",
      crossDomain: true,
      //              complete: function () {
      //
      //             document.getElementById("page21").style.display = '';
      //             }
      //             ,
      success: function (data1) {
        valuelist3 = [];
        valuelist4 = [];
        sessionStorage.setItem("firstgoodsa", "Y");
        sessionStorage.setItem("firstgoods", JSON.stringify([]));
        $("#loadingDiv").css("display", "none");
        $("#introFooter").css("display", "");
        $("#bodydiv").css("display", "");
        if (data1.status == 200) {
          sessionStorage.setItem("ajaxData", JSON.stringify(data1["result"]));
          setBrandnamet(sessionStorage.getItem("brandname"));

          let btn_value = sessionStorage.getItem("btn_value");
          let btn_value2 = btn_value.split(",");
          setPrice(162000 * btn_value2.length);
          setPrice1(52000 * btn_value2.length);
          setPrice2(110000 * btn_value2.length);
          for (let i = 0; i < btn_value2.length; i++) {
            let dict = {};
            dict["valuelist"] = btn_value2[i];
            dict["valuelist2"] = checkblue;
            dict["valuelistc"] = "checkgray";
            valuelist3.push(data1["result"][i]);
            valuelist4.push(data1["result"][i]);
            setValuelist((valuelist) => [...valuelist, dict]);
          }
          sessionStorage.setItem("valuelist3", JSON.stringify(valuelist3));
          setCount(btn_value2.length);
          //                           for (let i = 0; i < data1['result'].length; i++) {
          //                           arraydata = data1['result'][i]['lastgoods'].split("+")
          //                               sessionStorage.setItem("result "+i + "", JSON.stringify(data1['result'][i]));
        }

        if (data1.status == 401) {
        }
        if (data1.status == 400) {
        }
      },
      error: function (request, status, error) {},
    });
  }, []);

  function getIndex(listS, valuelist) {
    return listS.findIndex((obj) => obj.valuelist === valuelist);
  }
  const [count3, setCount3] = useState([]);
  const [renderFlag, setRenderFlag] = useState(true);
  const [eventcss, setEventcss] = useState("disabled-link");
  const [nextbtn, setNextbtn] = useState(nextbtn_new);
  useEffect(() => {
    onKeyUP();
  });
  const [text, setText] = useState([
    "선택해제",
    checkblue,
    "smallChoiceBoxWhite",
  ]);
  const [price, setPrice] = useState(0);

  const onClick1 = (props, successList) => {
    let successProps = props.skarb;
    let successIndex = getIndex(successProps, successList.valuelist);
    let fir = sessionStorage.getItem("firstgoodsa");
    let firstgoodsList = JSON.parse(sessionStorage.getItem("firstgoods"));
    if (successList.valuelist2 == checkblue) {
      if (fir === "Y") {
        setPrice1(price1 - 52000);
        setPrice2(price2 - 110000);
      } else {
        setPrice1(price1 - 212000);
        setPrice2(price2 - 330000);
        const filteredList = firstgoodsList.filter(
          (item) => item !== successList.valuelist[0]
        );
        sessionStorage.setItem("firstgoods", JSON.stringify(filteredList));
      }

      $("#" + successList.valuelist[0]).css("color", "#b7b7b7");
      for (let i = 0; i < successProps.length; i++) {
        if (i == successIndex) {
          successProps[i]["valuelist2"] = checkgray;
        }
      }
      for (var i = 0; i < valuelist3.length; i++) {
        if (valuelist3[i]["lastgoods"][0] === successList.valuelist[0]) {
          valuelist3.splice(i, 1);
          i--;
        }
      }
      sessionStorage.setItem("valuelist3", JSON.stringify(valuelist3));
      setCount(count - 1);
    } else if (successList.valuelist2 == checkgray) {
      $("#" + successList.valuelist[0]).css("color", "#4a64f5");
      for (let i = 0; i < successProps.length; i++) {
        if (i == successIndex) {
          successProps[i]["valuelist2"] = checkblue;
        }
      }
      for (var i = 0; i < valuelist4.length; i++) {
        if (valuelist4[i]["lastgoods"][0] === successList.valuelist[0]) {
          valuelist3.push(valuelist4[i]);
        }
      }
      sessionStorage.setItem("valuelist3", JSON.stringify(valuelist3));
      setCount(count + 1);
      if (fir === "Y") {
        setPrice1(price1 + 52000);
        setPrice2(price2 + 110000);
      } else {
        setPrice1(price1 + 212000);
        setPrice2(price2 + 330000);
        firstgoodsList.push(successList.valuelist[0]);
        sessionStorage.setItem("firstgoods", JSON.stringify(firstgoodsList));
      }
    }
    setRenderFlag(!renderFlag);
    setCount3(successProps);
  };

  useEffect(() => {
    setValuelist(count3);
  }, [renderFlag, count3]);

  const onKeyUP = () => {
    if (count > 0) {
      $("#next_button").css("background-color", "#4a64f5");
      $("#dam").css("color", "#ffffff");
      setEventcss("");
      setNextbtn(new_blue);
    } else {
      $("#next_button").css("background-color", "#efefef");
      $("#dam").css("color", "#b7b7b7");
      setEventcss("disabled-link");
      setNextbtn(nextbtn_new);
    }
  };
  const setfirstn = () => {
    let fir = sessionStorage.getItem("firstgoodsa");
    let checkCategory = JSON.parse(sessionStorage.getItem("valuelist3"));
    if (isLoaded) {
      if (fir === "Y") {
        let asd = [];
        for (var i = 0; i < checkCategory.length; i++) {
          asd.push(checkCategory[i].lastgoods[0]);
        }
        sessionStorage.setItem("firstgoodsa", "N");
        sessionStorage.setItem("firstgoods", JSON.stringify(asd));
        setPrice1((prev) => prev + 160000 * count);
        setPrice2((prev) => prev + 220000 * count);
        setMonthtext(month1211);
      } else {
        // for(var i = 0; i < goodsList.length; i++){
        //   if (goodsList[i] === successList.valuelist[0]) {
        //     goodsList.splice(i, 1);
        //     i--;
        //   }
        // }
        sessionStorage.setItem("firstgoodsa", "Y");
        setPrice1((prev) => prev - 160000 * count);
        setPrice2((prev) => prev - 220000 * count);
        sessionStorage.setItem("firstgoods", JSON.stringify([]));
        setMonthtext(month121);
      }
    }
  };

  useEffect(() => {
    sessionStorage.setItem("nprice", price1 + price2);
  }, [price1, price2]);

  function CurrencyFormat({ amount }) {
    const formattedAmount = amount.toLocaleString("ko-KR", {
      style: "currency",
      currency: "KRW",
      maximumFractionDigits: 0, // 소수점 없이
    });

    return <span>{formattedAmount}</span>;
  }
  useEffect(() => {
    $("#next_button").css("background-color", "#efefef");
    $("#dam").css("color", "#b7b7b7");
    setEventcss("disabled-link");
    setNextbtn(nextbtn_new);
  }, []);
  useEffect(() => {
    // 데이터 불러오기 등의 작업
    setIsLoaded(true);
  }, []);
  return (
    <div>
      <MobileView>
        <div
          id="loadingDiv"
          style={{
            margin: "auto",
            maxWidth: "520px",
            fontFamily: "'Noto Sans KR', sans-serif",
          }}
        >
          <img
            src={load_mo}
            style={{
              width: "132px",
              marginTop: "230px",
              marginLeft: "calc(50% - 66px)",
            }}
          />
          <div>
            <img
              src={loadingdot}
              style={{
                width: "50px",
                marginTop: "16px",
                marginLeft: "calc(50% - 25px)",
              }}
            />
          </div>
          <div
            className="Bold"
            style={{ textAlign: "center", fontSize: "30px", marginTop: "22px" }}
          >
            <a>잠시만 기다려 주세요 :)</a>
          </div>
        </div>
        <div
          id="bodydiv"
          style={{
            margin: "auto",
            maxWidth: "520px",
            fontFamily: "'Noto Sans KR', sans-serif",
            display: "none",
          }}
        >
          <TopbarComponentWithoutPage path={"/brandcategoryregister"} />

          <div style={{ display: "inline-block" }}>
            <img
              src={asdzx}
              style={{
                width: "72px",
                position: "absolute",
                marginLeft: "16px",
                marginTop: "-65px",
              }}
            />
          </div>
          <div
            className="headLine2 Bold"
            style={{ display: "inline-block", marginLeft: "102px" }}
          >
            출원에 예상되는 기간,
            <br />
            비용을 확인하세요<a style={{ color: "#4A64F5" }}>!</a>
          </div>

          <div
            className="midbox12a"
            style={{
              paddingBottom: "60px",
            }}
          >
            <div
              style={{
                paddingTop: "20px",
                marginRight: "20px",
                marginLeft: "20px",
                textAlign: "left",
                borderBottom: "1px solid #b7b7b7",
                marginBottom: "12px",
              }}
            >
              <div style={{ display: "inline-block" }}>
                <div
                  style={{
                    backgroundColor: "#edeffe",
                    padding: "2px 6px",
                    borderBottom: "3px solid #4a64f5",
                    marginTop: "15px",
                  }}
                >
                  <a
                    className="Bold"
                    style={{
                      fontSize: "22px",
                    }}
                  >
                    {brandnamet}
                  </a>
                </div>
              </div>
              <div
                className="Medium"
                style={{
                  marginBottom: "18px",
                  fontSize: "16px",
                  lineHeight: "1.4",
                  marginTop: "12px",
                }}
              >
                <a style={{ fontSize: "16px" }}>
                  지금 출원하면{" "}
                  <a style={{ color: "#4a64f5" }}>
                    {monthtext}에 등록됩니다 :)
                  </a>
                  <br />
                  상표 보호를 위해 지금 바로 등록하세요!
                </a>
              </div>
            </div>
            <div>
              <div>
                <AnimalFriends
                  skarb={valuelist}
                  onClick1={onClick1}
                  text={text}
                  setfirstn={setfirstn}
                />
              </div>
            </div>
            <div
              style={{
                borderTop: "1px dashed #e0e0e0",
                marginTop: "25px",
                marginLeft: "20px",
                marginRight: "20px",
              }}
            >
              <div
                className="Regular"
                style={{
                  color: "#fff",
                  marginTop: "20px",
                  fontSize: "16px",
                  marginBottom: "10px",
                  backgroundColor: "#000",
                  paddingTop: "7px",
                  paddingBottom: "7px",
                  paddingLeft: "11px",
                  paddingRight: "11px",
                  borderRadius: "12px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={sclock}
                  style={{
                    width: "14px",
                    marginRight: "8px",
                    position: "relative",
                    top: "-1px",
                  }}
                />
                <p className="Bold1" style={{ display: "inline", margin: 0 }}>
                  심사기간 단축 옵션 선택 시
                </p>
                <label
                  className="switch2"
                  style={{ float: "right", color: "#fff", marginLeft: "auto" }}
                >
                  <input
                    id="checkFirst"
                    type="checkbox"
                    style={{ marginLeft: "7px" }}
                    onClick={() => setfirstn()}
                    className="chchch"
                  />
                  <span
                    style={
                      document.querySelector("#checkFirst")?.checked
                        ? { paddingTop: "2.9px", paddingLeft: "5px" }
                        : { paddingLeft: "35px", paddingTop: "1.2px" }
                    }
                    className="slider2 round Bold1"
                  >
                    {document.querySelector("#checkFirst")?.checked
                      ? ":D"
                      : "(:"}
                  </span>
                </label>
              </div>
              <div
                style={{
                  fontSize: "14px",
                  textAlign: "left",
                  paddingBottom: "22.5px",
                  borderBottom: "1px dashed #e0e0e0",
                }}
              >
                <span className="Medium" style={{ color: "#4a64f5" }}>
                  ‘심사기간 단축 옵션’
                </span>

                <span className="Regular" style={{ color: "#515151" }}>
                  을 선택하시면{" "}
                </span>
                <span
                  className="Medium"
                  style={{ textDecoration: "underline" }}
                >
                  15개월 이상 걸리는
                  <br />
                  특허청 심사기간을 약 2~3개월로 단축
                </span>
                <span className="Regular" style={{ color: "#515151" }}>
                  할수 있어요!
                </span>
              </div>
            </div>

            <div
              className="in2"
              style={{
                color: "#898989",
                paddingBottom: "20px",
                fontSize: "14px",
                borderBottom: "1px solid #b7b7b7",
                marginTop: "20px",
                marginLeft: "20px",
                marginRight: "20px",
              }}
            >
              <div className="Regular" style={{ color: "#515151" }}>
                <p style={{ display: "inline" }}>상표 출원 예상일</p>
                <span className="" style={{ float: "right", color: "#515151" }}>
                  {tomorow}
                </span>
              </div>

              <div
                className="Regular"
                style={{ color: "#515151", marginTop: "4px" }}
              >
                <p style={{ display: "inline" }}>심사 결과 통보 예상일 </p>
                <span
                  className=""
                  style={
                    document.querySelector("#checkFirst")?.checked
                      ? { color: "#b7b7b7", float: "right" }
                      : { color: "#000000", float: "right" }
                  }
                >
                  {month12}
                </span>
              </div>
              <div
                className="Regular"
                style={
                  document.querySelector("#checkFirst")?.checked
                    ? { color: "#4a64f5", marginTop: "4px" }
                    : { color: "#b7b7b7", marginTop: "4px" }
                }
              >
                <p style={{ display: "inline" }}>
                  → 심사기간 단축 옵션 선택 시
                </p>
                <span className="" style={{ float: "right" }}>
                  {month5}
                </span>
              </div>
            </div>
            <div
              style={
                document.querySelector("#checkFirst")?.checked
                  ? {
                      paddingTop: "3px",
                      paddingBottom: "47.5px",
                      paddingTop: "20px",
                      marginRight: "20px",
                      marginLeft: "20px",
                    }
                  : {
                      paddingTop: "3px",
                      paddingBottom: "27.5px",
                      paddingTop: "20px",
                      marginRight: "20px",
                      marginLeft: "20px",
                    }
              }
            >
              <div style={{}}>
                <div className="Regular">
                  <a
                    className="Medium"
                    style={{
                      fontSize: "16px",
                      marginTop: "15px",
                      color: "#515151",
                    }}
                  >
                    특허청 관납료
                  </a>
                  <a
                    style={{
                      cursor: "pointer",
                      float: "right",
                      color: "#898989",
                      fontSize: "14px",
                    }}
                    className={"priceFont Regular1"}
                  >
                    52,000원 X {count}건
                  </a>
                </div>
                <div
                  className="Regular"
                  style={
                    document.querySelector("#checkFirst")?.checked
                      ? {
                          fontSize: "14px",
                          color: "#4a64f5",
                          float: "right",
                          marginTop: "3px",
                        }
                      : { display: "none" }
                  }
                >
                  +160,000원 X {count}건
                </div>

                <div
                  className="Regular"
                  style={
                    document.querySelector("#checkFirst")?.checked
                      ? { marginTop: "24px" }
                      : { marginTop: "4px" }
                  }
                >
                  <a
                    className="Medium"
                    style={{
                      fontSize: "16px",
                      marginTop: "15px",
                      color: "#515151",
                    }}
                  >
                    네임텍상표 수수료
                  </a>
                  <a
                    style={{
                      cursor: "pointer",
                      float: "right",
                      color: "#898989",
                      fontSize: "14px",
                    }}
                    className={"priceFont Regular1"}
                  >
                    110,000원 X {count}건
                  </a>
                </div>
                <div
                  className="Regular"
                  style={
                    document.querySelector("#checkFirst")?.checked
                      ? {
                          fontSize: "14px",
                          color: "#4a64f5",
                          float: "right",
                          marginTop: "3px",
                        }
                      : { display: "none" }
                  }
                >
                  +220,000원 X {count}건
                </div>
              </div>
            </div>
            <div style={{ marginLeft: "15px" }}>
              <Link to="/brandregister">
                <img
                  src={rechoice}
                  style={{
                    width: "100px",
                    marginTop: "3px",
                    marginRight: "20px",
                    float: "right",
                  }}
                />
              </Link>
            </div>
          </div>

          <div style={{ height: "180px" }}></div>
          <div
            className="Medium"
            style={{
              position: "fixed",
              bottom: "60px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              backgroundColor: "#efefef",
              width: "100%",
              height: "60px",
              maxWidth: "520px",
              boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.14)",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              backgroundColor: "#fff",
              paddingLeft: "20px",
              paddingRight: "20px",
              boxSizing: "border-box",
            }}
          >
            <a
              className="Medium"
              style={{
                fontSize: "20px",
              }}
            >
              총계
            </a>
            <div
              style={{
                fontSize: "20px",
                textAlign: "right",
              }}
            >
              <span style={{ color: "#4a64f5" }}>
                {(price1 + price2).toLocaleString()}
              </span>{" "}
              원
            </div>
          </div>
          <Link to="/infomcheck" className={eventcss}>
            <div
              id="next_button"
              className="noto"
              style={{
                cursor: "pointer",
                position: "fixed",
                bottom: "0px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#efefef",
              }}
            >
              <div id="dam" style={{ color: "#b7b7b7" }}>
                {" "}
                다음
              </div>
            </div>
          </Link>
        </div>
      </MobileView>
      <BrowserView>
        <BrowserHeader />
        <div
          id="loadingDiv"
          style={{
            margin: "auto",
            maxWidth: "520px",
            fontFamily: "'Noto Sans KR', sans-serif",
          }}
        >
          <img
            src={load_mo}
            style={{
              width: "132px",
              marginTop: "230px",
              marginLeft: "calc(50% - 66px)",
            }}
          />
          <div>
            <img
              src={loadingdot}
              style={{
                width: "50px",
                marginTop: "16px",
                marginLeft: "calc(50% - 25px)",
              }}
            />
          </div>
          <div
            className="Bold"
            style={{ textAlign: "center", fontSize: "30px", marginTop: "22px" }}
          >
            <a>잠시만 기다려 주세요 :)</a>
          </div>
        </div>
        <div id="bodydiv" style={{ display: "none" }}>
          <div style={{ width: "1400px", paddingLeft: "calc(50% - 700px)" }}>
            <img
              src={ask5}
              style={{
                width: "690px",
                marginTop: "123px",
                marginLeft: "calc(50% - 602px)",
                marginBottom: "50px",
              }}
            />
            <div
              className="midbox123"
              style={{
                margin: "0 auto",
                border: "1px solid #e0e0e0",
                width: "850px",
                marginLeft: "calc(50% - 425px)",
                borderRadius: "10px",
                boxShadow: "1px 1px 4px 0 rgba(0, 0, 0, 0.16)",
              }}
            >
              <div
                style={{
                  paddingTop: "20px",
                  marginRight: "20px",
                  marginLeft: "20px",
                  textAlign: "center",
                  borderBottom: "1px solid #b7b7b7",
                  marginBottom: "25px",
                }}
              >
                <div style={{ display: "inline-block", textAlign: "center" }}>
                  <div
                    style={{
                      backgroundColor: "#edeffe",
                      padding: "2px 6px",
                      borderBottom: "3px solid #4a64f5",
                      marginTop: "15px",
                    }}
                  >
                    <a
                      className="Bold"
                      style={{
                        fontSize: "30px",
                      }}
                    >
                      {brandnamet}
                    </a>
                  </div>

                  <br />
                </div>
                <div
                  className="Bold"
                  style={{
                    marginBottom: "22.5px",
                    fontSize: "20px",
                    lineHeight: "1.6",
                  }}
                >
                  <a style={{ fontSize: "20px" }}>
                    지금 출원하면{" "}
                    <a style={{ color: "#4a64f5" }}>
                      {monthtext}에 등록됩니다 :)
                    </a>
                    <br />
                    상표 보호를 위해 지금 바로 등록하세요!
                  </a>
                </div>
              </div>
              <div>
                <div>
                  <AnimalFriends2
                    skarb={valuelist}
                    onClick1={onClick1}
                    text={text}
                    setfirstn={setfirstn}
                  />
                </div>
              </div>
              <div
                style={{
                  borderTop: "1px dashed #e0e0e0",
                  marginTop: "25px",
                  marginLeft: "20px",
                  marginRight: "20px",
                }}
              >
                <div
                  className="Regular"
                  style={{
                    color: "#fff",
                    marginTop: "24.5px",
                    fontSize: "19px",
                    marginBottom: "17px",
                    marginRight: "20px",
                    marginLeft: "20px",
                    backgroundColor: "#000",
                    paddingTop: "11px",
                    paddingBottom: "11px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    borderRadius: "12px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={sclock}
                    style={{
                      width: "19px",
                      marginRight: "8px",
                      position: "relative",
                      top: "-1px",
                    }}
                  />
                  <p className="Bold1" style={{ display: "inline", margin: 0 }}>
                    심사기간 단축 옵션 선택 시
                  </p>
                  <label
                    className="switch1"
                    style={{
                      float: "right",
                      color: "#fff",
                      marginLeft: "auto",
                    }}
                  >
                    <input
                      id="checkFirst"
                      type="checkbox"
                      style={{ marginLeft: "3px" }}
                      onClick={() => setfirstn()}
                      className="chchch"
                    />
                    <span
                      style={
                        document.querySelector("#checkFirst")?.checked
                          ? { paddingTop: "4px", paddingLeft: "8px" }
                          : { paddingLeft: "44px", paddingTop: "2px" }
                      }
                      className="slider1 round Bold1"
                    >
                      {document.querySelector("#checkFirst")?.checked
                        ? ":D"
                        : "(:"}
                    </span>
                  </label>
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    textAlign: "center",
                    paddingBottom: "22.5px",
                    borderBottom: "1px dashed #e0e0e0",
                  }}
                >
                  <span className="Medium" style={{ color: "#4a64f5" }}>
                    ‘심사기간 단축 옵션’
                  </span>

                  <span className="Regular" style={{ color: "#515151" }}>
                    을 선택하시면{" "}
                  </span>
                  <span
                    className="Medium"
                    style={{ textDecoration: "underline" }}
                  >
                    {" "}
                    15개월 이상 걸리는 특허청 심사기간을 약 2~3개월로 단축
                  </span>
                  <span className="Regular" style={{ color: "#515151" }}>
                    할 수 있어요!
                  </span>
                </div>
              </div>

              <div
                className="in2"
                style={{
                  color: "#898989",
                  display: "inline-block",
                  width: "770px",
                  paddingBottom: "27.5px",
                  fontSize: "19px",
                  borderBottom: "1px solid #b7b7b7",
                  marginTop: "27px",
                  marginLeft: "20px",
                  marginRight: "20px",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                }}
              >
                <div
                  className="Regular"
                  style={{ color: "#515151", marginTop: "12px" }}
                >
                  <p style={{ display: "inline" }}>상표 출원 예상일</p>
                  <span
                    className=""
                    style={{ float: "right", color: "#515151" }}
                  >
                    {tomorow}
                  </span>
                </div>

                <div
                  className="Regular"
                  style={{ color: "#515151", marginTop: "12px" }}
                >
                  <p style={{ display: "inline" }}>심사 결과 통보 예상일 </p>
                  <span
                    className=""
                    style={
                      document.querySelector("#checkFirst")?.checked
                        ? { color: "#b7b7b7", float: "right" }
                        : { color: "#000000", float: "right" }
                    }
                  >
                    {month12}
                  </span>
                </div>
                <div
                  className="Regular"
                  style={
                    document.querySelector("#checkFirst")?.checked
                      ? { color: "#4a64f5", marginTop: "12px" }
                      : { color: "#b7b7b7", marginTop: "12px" }
                  }
                >
                  <p style={{ display: "inline" }}>
                    → 심사기간 단축 옵션 선택 시
                  </p>
                  <span className="" style={{ float: "right" }}>
                    {month5}
                  </span>
                </div>
              </div>
              <div
                style={
                  document.querySelector("#checkFirst")?.checked
                    ? {
                        paddingTop: "3px",
                        paddingBottom: "47.5px",
                        paddingTop: "27.5px",
                        borderBottom: "1px dashed #e0e0e0",
                        marginRight: "36px",
                        marginLeft: "36px",
                        marginBottom: "27.5px",
                      }
                    : {
                        paddingTop: "3px",
                        paddingBottom: "27.5px",
                        paddingTop: "27.5px",
                        borderBottom: "1px dashed #e0e0e0",
                        marginRight: "36px",
                        marginLeft: "36px",
                        marginBottom: "27.5px",
                      }
                }
              >
                <div style={{}}>
                  <div className="Regular">
                    <a
                      className="Bold"
                      style={{
                        fontSize: "22px",
                        marginTop: "15px",
                        color: "#515151",
                      }}
                    >
                      특허청 관납료
                    </a>
                    <a
                      style={{
                        cursor: "pointer",
                        float: "right",
                        color: "#898989",
                        fontSize: "22px",
                      }}
                      className={"priceFont Regular1"}
                    >
                      52,000원 X {count}건
                    </a>
                  </div>
                  <div
                    className="Regular"
                    style={
                      document.querySelector("#checkFirst")?.checked
                        ? {
                            fontSize: "19px",
                            color: "#4a64f5",
                            float: "right",
                            marginTop: "3px",
                          }
                        : { display: "none" }
                    }
                  >
                    +160,000원 X {count}건
                  </div>

                  <div
                    className="Regular"
                    style={
                      document.querySelector("#checkFirst")?.checked
                        ? { marginTop: "40px" }
                        : { marginTop: "20px" }
                    }
                  >
                    <a
                      className="Bold"
                      style={{
                        fontSize: "22px",
                        marginTop: "15px",
                        color: "#515151",
                      }}
                    >
                      네임텍상표 수수료
                    </a>
                    <a
                      style={{
                        cursor: "pointer",
                        float: "right",
                        color: "#898989",
                        fontSize: "22px",
                      }}
                      className={"priceFont Regular1"}
                    >
                      110,000원 X {count}건
                    </a>
                  </div>
                  <div
                    className="Regular"
                    style={
                      document.querySelector("#checkFirst")?.checked
                        ? {
                            fontSize: "19px",
                            color: "#4a64f5",
                            float: "right",
                            marginTop: "3px",
                          }
                        : { display: "none" }
                    }
                  >
                    +220,000원 X {count}건
                  </div>
                </div>
              </div>
              <div style={{ marginLeft: "15px" }}>
                <div>
                  <a
                    className="Bold"
                    style={{ fontSize: "22px", marginLeft: "20px" }}
                  >
                    총계
                  </a>
                  <a
                    className="Medium"
                    style={{
                      fontSize: "22px",
                      color: "#4a64f5",
                      float: "right",
                      marginRight: "36px",
                    }}
                  >
                    {(price1 + price2).toLocaleString()}원
                  </a>
                </div>
                <Link to="/brandregister">
                  <img
                    src={rechoice}
                    style={{
                      width: "130px",
                      position: "absolute",
                      marginTop: "3px",
                      marginLeft: "669px",
                      marginTop: "20px",
                    }}
                  />
                </Link>
              </div>
              <div style={{ height: "60px", marginBottom: "30px" }}></div>
            </div>
            <div style={{ height: "300px" }}></div>
          </div>
        </div>
        <div
          id="introFooter"
          style={{
            float: "right",
            backgroundColor: "#f5f6ff",
            height: "116px",
            lineHeight: "110px",
            display: "none",
          }}
        >
          <Link to="/infomcheck" className={eventcss}>
            <img
              src={nextbtn}
              style={{
                cursor: "pointer",
                width: "200px",
                marginLeft: "20px",
                marginTop: "27px",
                float: "right",
                marginRight: "calc(50% - 485px)",
              }}
            />
          </Link>
          <a href="http://pf.kakao.com/_lWuhs/chat">
            <img
              src={kakaoaa}
              style={{
                cursor: "pointer",
                width: "283px ",
                marginTop: "27px",
                float: "right",
              }}
            />
          </a>
          <div
            className="Bold1"
            style={{ fontSize: "30px", marginLeft: "calc(50% - 485px)" }}
          >
            <a>
              총 <a style={{ color: "#4a64f5" }}>{count}</a>건 /{" "}
              <a style={{ color: "#4a64f5" }}>
                {(price1 + price2).toLocaleString()}
              </a>{" "}
              원
            </a>
          </div>
        </div>
      </BrowserView>
    </div>
  );
}

export default Success;
